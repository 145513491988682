import React from 'react'
import Resetpwd from './resetpwd';

const ResetPwdPage = props => {
    return (
        <div>
            <Resetpwd {...props} />
        </div>
    )
}

export default ResetPwdPage
