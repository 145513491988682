import React from "react";
import SponsorLayout from "../common/sponsorlayout";

const Terms = () => {
  return (
    <div>
      <SponsorLayout title="WOW TAMIL NADU 2023 - Terms and Conditions">
        <p>
          “WOW TAMILNADU 2023” Brand name and the event Initiated, owned,
          Operated & Organized by Global Media Box Innovations Pvt Ltd (referred
          as “Organizer”). On participating the online contest through our
          official website site wowtamilnadu.com each entrant/contestant accepts
          the Terms and Conditions Laid by organizer and the associated Sponsors
          for WOW TAMILNADU.
        </p>
        <p>
          Anyone can enter, including professional and amateur photographers.
          However, minors under the age of 18 must receive permission from a
          parent or guardian. There are no restrictions whatsoever on
          photographic equipment used to shoot photos to be entered. Any
          photographic device from a DSLR to smartphone may be used.
        </p>
        <h5>What images CANNOT participate in WOW TAMILNADU AWARDS?</h5>
        <ul>
          <li>Images you do not have authorship of.</li>
          <li>Images you do not have the exclusive image rights to.</li>
          <li>
            Images for which you have not received the consent of any
            identifiable person appearing in the image, prior to it being
            published or sold.
          </li>
          <li>Images that are not related to the theme of the contest.</li>
          <li>
            It is explicitly prohibited to publish any images with:
            <ul>
              <li>Xenophobic, homophobic, racist or sexist content.</li>
              <li>Content inciting hatred or violence.</li>
              <li>Obscene or pornographic content.</li>
              <li>Content which threatens the rights of a minor.</li>
              <li>Content which threatens individual rights or freedom.</li>
              <li>
                Content which threatens the right to intimacy of a third party
              </li>
              <li>Content which has not been legally obtained.</li>
              <li>Content which implies sexual or religious discrimination.</li>
              <li>
                Distasteful content, contrary to general accepted principles of
                morality, and which might provoke social rejection; be
                detrimental to the dignity of third persons, or infringe moral
                and/or public order.
              </li>
              <li>
                Content which is defamatory or disrespectful to a third party?
              </li>
              <li>Content revealing secrets of a third party.</li>
              <li>
                Content which might endanger the rights of
                industrial/intellectual and image rights of third parties.
              </li>
              <li>
                Content which may infringe the rights of intimacy, honour and
                individual image of third parties.
              </li>
            </ul>
          </li>
        </ul>
        <h5>Registration Fees</h5>
        <p>
          Registration fee of INR.200 is applicable per participant and this fee
          is non-refundable. The online payment gateway is handled by EEEHAAA
          Travel and Tourism services. One time registration allows one
          participant to submit 10 Photographs and 10 videos in total for the
          contest.
        </p>
        <h5>Selection Process</h5>
        <p>
          In the finalists stage Best photos, Reels & Videos are chosen based on
          the score they have obtained from our judges appointed by the
          organizer. Top 100 Photographs that comply with the basic
          participation requirements will participate in the final stage.
        </p>
        <p>
          All participants and contest winners will grant all rights related to
          the photographs & videos to the sponsoring brand, also will win the
          previously described prize. On the other hand, the winner will benefit
          from the opportunity to become an ambassador of the sponsoring brand,
          thus extending his/her participation with it. Once the contest has
          completed WOW TAMILNADU team will contact the winner to arrange prize
          delivery and possible future collaborations with the sponsor brand.
        </p>
        <h5>Copyrights to Photo Entries</h5>
        <p>
          Copyrights or ownership rights to any image submitted as an entry
          shall remain with the respective entrant. However, to the extent
          permitted by law, by submitting the entry the entrant grants
          “Organiser” the perpetual, royalty free, and irrevocable, worldwide
          rights to feature, display, post, copy, print, or otherwise use the
          submitted images in any of “Organiser” websites, e-mails, catalogues,
          posters, magazine or newspaper articles, books, third-party websites,
          exhibitions, Social Media Posts and any promotional material,
          globally, during the contest and for a period of four (4) years after
          the contest is over.
        </p>
        <h5>Privacy Notice</h5>
        <p>
          Each entry including title and descriptions should not contain
          personally identifiable information about you or any other persons.
        </p>
        <p>
          Photos are limited only, in its entirety, to a single work of original
          material taken by the contest entrant and to which he or she retains
          all copyrights. Any conflicts or dispute that result from featuring
          subjects for which the rights do not belong to the entrant must be
          settled by the entrant and “Organiser” will incur no liability
          whatsoever. If a violation of copyrights is identified, that entry
          will be disqualified. If a violation of copyrights is identified after
          winners are announced, the win may be revoked and the entrant will
          lose the right to claim a prize. Depending on the country in which a
          winner resides, the prize may have to be changed in case the prize
          does not meet local standards and regulations. If the subject of a
          photo is a person, permission must be received from that person
          (parent or guardian in the case of a minor) before the photo can be
          entered in the contest.
        </p>
        <p>All rights reserved WOW TAMILNADU 2023 - wowtamilnadu.com</p>
      </SponsorLayout>
    </div>
  );
};

export default Terms;
