//modal
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

//toast
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const CLEAR_TOAST = 'CLEAR_TOAST'

//http
export const _FETCHING = '_FETCHING'
export const _FULFILLED = '_FULFILLED'
export const _REJECTED = '_REJECTED'
export const _CLEAR = '_CLEAR'
export const _ADD_DATA = '_ADD_DATA'
export const _REMOVE_DATA = '_REMOVE_DATA'
export const _UPDATE_DATA = '_UPDATE_DATA'
export const _SET_CACHE = '_SET_CACHE'

//user
export const USER = 'USER'
export const CATEGORIES = 'CATEGORIES'
export const PACKAGES = 'PACKAGES'
export const MASTER_VALUES = 'MASTER_VALUES'
export const LANDING = 'LANDING'
export const MULTISELECT = 'MULTISELECT'
export const MULTISELECTVIDEO = 'MULTISELECTVIDEO'

//photos
export const PRIVATE_COLLECTION = 'PRIVATE_COLLECTION'
export const PUBLISHED_COLLECTION = 'PUBLISHED_COLLECTION'

//videos
export const PRIVATE_VIDEO_COLLECTION = 'PRIVATE_VIDEO_COLLECTION'
export const PUBLISHED_VIDEO_COLLECTION = 'PUBLISHED_VIDEO_COLLECTION'

//urls
export const login_url = 'auth/login/'
export const register_url = 'auth/register/'
export const forgot_url = 'auth/forgot-password/'
export const logout_url = 'auth/logout/'
export const changepwd_url = 'auth/change-password/'
export const activate_url = 'auth/activate-account/'
export const resetpwd_url = 'auth/reset-password/'
export const photos_url = 'photos/'
export const videos_url = 'videos/'
export const cat_url = 'master/categories/'
export const fbauth_url = 'auth/facebook-login/'
export const gglauth_url = 'auth/google-login/'
export const packages_url = 'package/'
export const profile_url = 'users/'
export const master_url = 'master/categories/master_value/'
export const transcation_url = 'transaction/razorpay/'
export const payment_success_url = 'transaction/razorpay/payment_success/'
export const landing_url = 'master/landing-page/'
export const contact_url = 'master/contact-us/'
