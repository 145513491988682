import React from 'react'
import Loader from '../common/loader';

const CenterLoader = () => {
    return (
        <div className="flex-center">
            <Loader />
        </div>
    )
}

export default CenterLoader
